import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-ver-foto-component',
  templateUrl: './ver-foto-component.component.html',
  styleUrls: ['./ver-foto-component.component.scss'],
})
export class VerFotoComponentComponent implements OnInit {
  @Input() foto: any;
 
  sliderOpts = {
    zoom: true,
    slidesPerView: 1,
    freeMode: false,
  };
 
  constructor(private modalController: ModalController) { }
 
  ngOnInit() { }
 
  ionViewDidEnter(){
    //this.slides.update();
  }

 
  close() {
    this.modalController.dismiss();
  }
 
}
