import { Component, OnInit } from '@angular/core';
import { ActionSheetController, ModalController, NavParams, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { InAppBrowser,InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { VerFotoComponent } from '../ver-foto/ver-foto.component';
import { Plugins, } from '@capacitor/core';
import { CameraResultType, CameraSource } from '@capacitor/camera';
import { LoadingService } from 'src/app/services/loading.service';
import { ApiService } from 'src/app/services/api.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { allowedNodeEnvironmentFlags } from 'process';
const { Camera } = Plugins;

@Component({
  selector: 'app-modal-ver-abandonos',
  templateUrl: './modal-ver-abandonos.component.html',
  styleUrls: ['./modal-ver-abandonos.component.scss'],
})
export class ModalVerAbandonosComponent implements OnInit {

  aclaracion:any = this.navParams.get('aclaracion');
  urlApi = environment.endpoint;
  estatus:any;
  guardado:boolean = false;
  faltaEscrutinio:boolean = false;
  faltaDirector:boolean = false;

  constructor(
    private modalController:ModalController,
    private navParams:NavParams,
    private loading:LoadingService,
    private api:ApiService,
    private alert:AlertsService
  ) {

   }

  ngOnInit() {
    console.log(this.aclaracion)
    if(this.aclaracion.falta){
      if(this.aclaracion.tipoAbandono == "accidente"){
        if(this.aclaracion.reporte.escrutinio){
          this.faltaDirector = true;
          this.faltaEscrutinio = false;
        }
        else{
          this.faltaEscrutinio = true;
          this.faltaDirector = false;
        }
      }

    }
  }

  cerrarModal(){
    this.modalController.dismiss();
  }

  formChanged(){
    this.guardado = false;
  }



  validar(){
    let form = {
      "idReporte": this.aclaracion.reporte._id
    }
    console.log(form)
    this.loading.showLoader("Guardando información").then(()=>{
    this.api.actualizarReporteAbandono(form).subscribe((data:any) => 
    {
       this.loading.hideLoader().then(()=>{
        console.log("respuesta");
        console.log(data)
        if(data.listo){
          this.alert.presentAlert("Correcto","Ya se le dió la reincorporación!").then((data=>{
            this.cerrarModal();
          }))
        }
        else{
          if(data.reporte.escrutinio){
            this.alert.presentAlert("Correcto","Falta la aprobación de Dirección de carrera").then((data=>{
              this.faltaDirector = true;
              this.faltaEscrutinio = false;
              // this.cerrarModal();
            }))
          }
          else{
            this.alert.presentAlert("Correcto","Falta la aprobación de Escrutinio").then((data=>{
              this.faltaEscrutinio = true;
              this.faltaDirector = false;
              // this.cerrarModal();
            }))
          }

        }

        // if listooo me salgo y muestro alerta
        // if no listo no me salgo y muestro una alerta que dice que necesita otra aprobación


       })

    },(err) => 
    {
       this.loading.hideLoader().then(()=>{
        console.log(err.error.message)
        this.alert.presentAlert("Error",err.error.message)
       })
    });
  });
  }



}
